import React from 'react'
import styled from 'styled-components'
import Link from 'gatsby-link'
import GlobalWrapper from 'components/global-wrapper'
import ContentWrapper from 'components/contentWrapper'
import Header from 'components/header'
import Footer from 'components/footer'
import Seo from 'components/seo'

const Center = styled.div`
  text-align: center;
  min-height: calc(100vh - 162px);
  padding-top: 4vw;
`

const NotFoundPage = () => {
  return (
    <GlobalWrapper>
      <Seo title="404" />
      <Header />
      <ContentWrapper>
        <Center>
          <h1>404</h1>
          <p>The page you were looking for does not exist.</p>
          <Link to="/">Back to Homepage</Link>
        </Center>
      </ContentWrapper>
      <Footer />
    </GlobalWrapper>
  )
}

export default NotFoundPage
